import {createSlice} from "@reduxjs/toolkit";
import {loadListFromBackend} from "../utils/api/loadListFromBackend";

export const teamsSlice = createSlice({
  name: "teams",
  initialState: {
    list: [],
    init: false,
    editing: {},
    cloning: undefined,
  },
  reducers: {
    initContext: (state, action) => {
      state.init = action.payload;
    },
    initList: (state, action) => {
      state.init = action.payload.project;
      state.list = action.payload.list || [];
    },
  },
});

const asyncActions = {
  loadList: () => async (dispatch, getState) => {
    const state = getState();
    const projectId = state.currentProject.project._id;

    await loadListFromBackend(
      "teams",
      projectId,
      state.teams.init,
      () => dispatch(teamsActions.initContext(projectId)),
      (data) => dispatch(teamsActions.initList({list: data, project: projectId}))
    );
  },
};

export const teamsSelectors = {
  selectList: (state) => state.teams.list,
};

export const teamsReducer = teamsSlice.reducer;

export const teamsActions = {
  ...teamsSlice.actions,
  ...asyncActions,
};
