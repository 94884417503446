import {getSessionSubscription} from "../../../utils/registrationsUtilities";

export const getEndpointInSession = (endpoint, session, registrations?) => {
  return endpoint === "categories"
    ? session.activity.category
    : endpoint === "activities"
    ? session.activity
    : endpoint === "teams"
    ? session.team
    : endpoint === "sessions"
    ? session
    : endpoint === "places"
    ? session.places
    : endpoint === "stewards"
    ? session.stewards
    : endpoint === "registrations"
    ? registrations?.filter((r) => getSessionSubscription(r, session))
    : []; // Not supposed to happen at all but just in case
};
