import {useEffect} from "react";
import * as serviceWorkerRegistration from "../../app/services/serviceWorkerRegistration";
import {t} from "i18next";
import {AppMethods} from "./useThemedAppMethods";

// Service worker registration - if an update is available, reload the page without asking the user at all.
// Not the best behavior, but this is the less complicated.
export const useRegisterServiceWorker = () => {
  useEffect(() => {
    serviceWorkerRegistration.register({
      onUpdate: (registration: ServiceWorkerRegistration) => {
        AppMethods.message.loading(t("common:pwa.newVersionAvailable")).then(() => {
          try {
            console.log('registration.waiting.postMessage({type: "SKIP_WAITING"})');
            (registration.waiting as ServiceWorker | null)?.postMessage({
              type: "SKIP_WAITING",
            });
            console.log("Attempting reload...");
            window.location.reload();
          } catch (e) {
            console.error("Huhooo error : ", e);
            AppMethods.message.warning(t("common:pwa.reloadFailedPleaseCloseTabs"));
          }
        });
      },
    });
  }, []);
};
