import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {displayNotification} from "../../utils/displayNotification";
import {DownloadOutlined} from "@ant-design/icons";
import {App, Button} from "antd";
import {t} from "i18next";
import {URLS} from "../../app/configuration";
import {registrationsSelectors} from "../../features/registrations";
import {dayjsBase} from "../../app/services/dayjs";

let deferredPrompt;

// Inspired from https://github.com/adueck/pwa-install-demo/
export default function PwaInstallPromptHandler({
  shouldShow,
  delay = 3000,
}: {
  shouldShow: (currentRegistration: any | undefined) => boolean,
  delay?: number,
}) {
  const {message, notification} = App.useApp();
  const currentRegistration = useSelector(registrationsSelectors.selectCurrent);
  const [canInstall, setCanInstall] = useState(false);

  const beforeInstallPrompt = (e) => {
    // Check if the user agent is mobile
    let isMobile = /Android|iPhone/i.test(navigator.userAgent);
    if (isMobile) {
      // Prevent the mini-infobar from appearing and stash the event so it can be triggered later.
      e.preventDefault();
      deferredPrompt = e;

      // If the ignored date is far enough, re-notify the user about PWA with a prompt
      // Let one month before each time when the user ignores stuff
      const datePwaIgnored = localStorage.getItem("pwa-install-prompt-ignored-date");
      setCanInstall(
        datePwaIgnored ? dayjsBase().isAfter(dayjsBase(datePwaIgnored).add(1, "month")) : true
      );
    }
  };

  // Triggered when navigator has app ready to install
  useEffect(() => {
    window.addEventListener("beforeinstallprompt", beforeInstallPrompt);
    return () => window.removeEventListener("beforeinstallprompt", beforeInstallPrompt);
  }, [currentRegistration?.project]);

  // Triggered when the user clicks on the Install button in our popup
  const handleInstallClick = () => {
    // Show the install prompt
    deferredPrompt.prompt();
    // Wait for the user to respond to the prompt. If yes, then destroy the notification
    deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === "accepted") {
        notification.destroy("pwa-install-prompt");
        setCanInstall(false);
      }
    });
  };

  const ignorePwa = () => {
    notification.destroy("pwa-install-prompt");
    localStorage.setItem("pwa-install-prompt-ignored-date", dayjsBase().toISOString());
    setCanInstall(false);
    message.info(t("common:pwa.installPrompt.ignoreSuccess"));
  };

  // Trigger the install notification once all conditions are met
  useEffect(() => {
    if (canInstall && shouldShow(currentRegistration)) {
      const timeOutBeforeInstallNotification = setTimeout(
        () =>
          displayNotification("info", "pwa-install-prompt", {
            btn: (
              <div className="containerH buttons-container">
                <Button onClick={ignorePwa}>{t("common:ignore")}</Button>
                <Button type="primary" onClick={handleInstallClick}>
                  {t("common:pwa.installPrompt.button")}
                </Button>
              </div>
            ),
            icon: <DownloadOutlined style={{color: "var(--noe-primary)"}} />,
            message: t("common:pwa.installPrompt.message", {
              context: URLS.CURRENT === URLS.ORGA_FRONT ? "orgaFront" : "inscriptionFront",
            }),
          }),
        delay
      );

      return () => clearTimeout(timeOutBeforeInstallNotification);
    }
  }, [canInstall, currentRegistration, shouldShow]);

  return null;
}
