import {
  createLocalStorageGetSetter,
  getLocalStorage,
  setLocalStorage,
} from "./localStorageUtilities";
import {loadAllEntities} from "./api/loadAllEntities";
import {Alert, Button, App, Popconfirm, Switch, Tooltip} from "antd";
import localforage from "localforage";
import {persistReducer, persistStore} from "redux-persist";
import hardSet from "redux-persist/lib/stateReconciler/hardSet";
import React, {useEffect, useLayoutEffect, useState} from "react";
import {DownloadOutlined, SyncOutlined, WifiOutlined} from "@ant-design/icons";
import {configureStore} from "@reduxjs/toolkit";
import {useDispatch, useSelector} from "react-redux";
import {currentProjectSelectors} from "../features/currentProject";
import {useOnlineStatus} from "../common/hooks/useOnlineStatus";
import {Trans, useTranslation} from "react-i18next";
import "../app/services/i18n"; // in order to initialize it properly before using it
import {t} from "i18next";
import {SwitchInput} from "../common/inputs/SwitchInput";
import {useNavigate} from "react-router-dom";

/**
 * Variables setters and getters
 */

export const displayOfflineModeFeatureGetSetter = createLocalStorageGetSetter(
  "display-offline-mode-feature",
  false
);
export const DISPLAY_OFFLINE_MODE_FEATURE = displayOfflineModeFeatureGetSetter();

export let OFFLINE_MODE = DISPLAY_OFFLINE_MODE_FEATURE && getLocalStorage("offline-mode", false);

export const activatingOfflineModeGetSetter = createLocalStorageGetSetter(
  "activating-offline-mode",
  false
);
export let ACTIVATING_OFFLINE_MODE =
  DISPLAY_OFFLINE_MODE_FEATURE && activatingOfflineModeGetSetter();

/**
 * Returns the reducer depending of the activation of the offline mode or not
 * @param mainReducer the main reducer
 * @param enhancers optional enhancers
 * @return {*}
 */
export const getConfiguredStoreAndPersistor = (mainReducer, enhancers: Array<any> = undefined) => {
  const store = configureStore({
    reducer: OFFLINE_MODE
      ? persistReducer({key: "root", storage: localforage, stateReconciler: hardSet}, mainReducer)
      : mainReducer,
    enhancers,
  });
  return [store, OFFLINE_MODE && persistStore(store)];
};

/**
 * Activates or not the variables for offline mode, on start of the app.
 *
 * TODO : should be refactored to a ContextHandler properly
 */
export const useInitializeOfflineModeOnStart = () => {
  const {message} = App.useApp();

  useLayoutEffect(() => {
    // Get current state of offline mode in local storage
    const shouldSave = activatingOfflineModeGetSetter();

    // If Offline mode is active, just notify it. Timeout to allo translation to load properly
    !shouldSave &&
      OFFLINE_MODE &&
      setTimeout(() => message.success(t("common:offlineMode.activeNotification"), 5), 500);

    // If we should activate offline mode right now, here is what we do
    if (!OFFLINE_MODE && shouldSave) {
      // Activate offline mode
      OFFLINE_MODE = true;
      setLocalStorage("offline-mode", true);
    }
  });
};

/**
 * Used to load all entities of the project automatically on start of the app
 * @param registrationId registration Id
 */
export const useOfflineMode = (registrationId) => {
  const {message} = App.useApp();
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const currentProject = useSelector(currentProjectSelectors.selectProject);
  const setOfflineMode = useSetOfflineMode();

  const prepareOfflineMode = async (dispatch, registrationId) => {
    if (ACTIVATING_OFFLINE_MODE && registrationId) {
      message.loading({
        content: t("common:offlineMode.preparing"),
        key: "offline-preparation",
        duration: 0,
      });

      await loadAllEntities(dispatch);

      activatingOfflineModeGetSetter.set(false);
      ACTIVATING_OFFLINE_MODE = false;

      message.success({
        content: <Trans i18nKey="offlineMode.successMessage" ns="common" />,
        key: "offline-preparation",
        duration: 8,
        onClick: () => message.destroy("offline-preparation"),
      });
    }
  };

  useEffect(() => {
    currentProject._id &&
      prepareOfflineMode(dispatch, currentProject._id, registrationId).catch(() => {
        message.error(t("common:offlineMode.oopsDidntWork"));
        setOfflineMode(false);
      });
  }, [registrationId, currentProject._id]);
};

export const useRedirectToProjectIfOffline = () => {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const currentProject = useSelector(currentProjectSelectors.selectProject);
  const {message} = App.useApp();

  // Redirect automatically to the saved project when offline
  useEffect(() => {
    if (OFFLINE_MODE && currentProject._id)
      message
        .loading(t("common:offlineMode.redirectingToSavedProject"), 2)
        .then(() => navigate(`/${currentProject.slug || currentProject._id}`));
  }, [currentProject._id]);
};

const OfflineSwitch = ({offlineState, onClick}) => (
  <Switch
    checked={offlineState}
    onClick={onClick}
    style={{outline: "2px solid white", marginTop: -8}}
    checkedChildren={
      <>
        <DownloadOutlined /> {t("common:offlineMode.offline")}
      </>
    }
    unCheckedChildren={
      <>
        <WifiOutlined /> {t("common:offlineMode.online")}
      </>
    }
  />
);

/**
 * Switch component to activate of deactivate the offline mode
 * @return {ReactNode}
 */
export const OnlineOfflineSwitch = () => {
  const {t} = useTranslation();
  const [offlineState, setOfflineState] = useState(OFFLINE_MODE);
  const online = useOnlineStatus();
  const setOfflineMode = useSetOfflineMode();

  const shouldWarnOnDeactivation = !online && OFFLINE_MODE;

  const toggleOfflineMode = () => {
    setOfflineMode(!offlineState);
    setOfflineState(!offlineState);
  };

  return (
    <>
      {shouldWarnOnDeactivation ? (
        <Popconfirm
          title={<Trans i18nKey="offlineMode.deactivationWarning.title" ns="common" />}
          placement="topLeft"
          okText={t("common:offlineMode.deactivationWarning.deactivateAnyway")}
          okButtonProps={{danger: true}}
          cancelText={t("common:cancel")}
          onConfirm={toggleOfflineMode}>
          <OfflineSwitch offlineState={offlineState} />
        </Popconfirm>
      ) : (
        <OfflineSwitch onClick={toggleOfflineMode} offlineState={offlineState} />
      )}
      {offlineState && online && (
        <Tooltip title={t("common:offlineMode.refreshData")}>
          <Button
            ghost
            style={{marginLeft: 8, borderWidth: 2}}
            className={"fade-in"}
            icon={<SyncOutlined />}
            onClick={async () => {
              await setOfflineMode(false, false);
              await setOfflineMode(true, false);
              window.location.reload();
            }}
          />
        </Tooltip>
      )}
    </>
  );
};

/**
 * The switch component to activte or deactivate completely the display of the offline mode feature
 * @return {ReactNode}
 */
export const DisplayOfflineModeFeatureSwitch = () => {
  const {t} = useTranslation();
  const notifyReloadMessage = useNotifyReloadMessage();

  return (
    <>
      <Alert
        style={{marginBottom: 26}}
        message={t("common:offlineMode.presentation.message")}
        description={
          <Trans
            ns="common"
            i18nKey="offlineMode.presentation.description"
            components={{syncIcon: <SyncOutlined />}}
          />
        }
      />
      <SwitchInput
        label={t("common:offlineMode.displayFeature")}
        defaultChecked={displayOfflineModeFeatureGetSetter()}
        onChange={(val) => {
          displayOfflineModeFeatureGetSetter.set(val);
          if (!val) {
            localforage.clear();
            OFFLINE_MODE = false;
            setLocalStorage("offline-mode", false);
          }
          notifyReloadMessage();
        }}
      />
    </>
  );
};

//////////
// Internal hooks
//////////

const useNotifyReloadMessage = () => {
  const {message} = App.useApp();

  return () =>
    message.open({
      key: "reload",
      content: (
        <div className="containerH buttons-container">
          <span>{t("common:offlineMode.reloadMessage.content")}</span>
          <Button type="primary" onClick={() => window.location.reload()}>
            {t("common:offlineMode.reloadMessage.button")}
          </Button>
        </div>
      ),
      duration: 10,
    });
};

/**
 * Sets the offline mode to be activated or deactivated on next start of the app
 */
const useSetOfflineMode = () => {
  const notifyReloadMessage = useNotifyReloadMessage();

  /**
   * @param value activation of the offline mode
   * @param notifyReload if we should notify the usere about reloading th page
   */
  return async (value, notifyReload = true) => {
    activatingOfflineModeGetSetter.set(value);
    if (!value) {
      setLocalStorage("offline-mode", false);
      await localforage.clear();
    }
    notifyReload && notifyReloadMessage();
  };
};
