import {FieldComp} from "../common/components/FieldsBuilder/FieldComp/FieldComp";

// TODO divide this function : flattenInputFieldComponents / flattenAllFieldComponents
export const flattenFieldComponents = (
  components: Array<FieldComp>,
  keepOnlyInputs = true
): Array<FieldComp> => {
  if (!components) return [];

  const tree = components.map((comp) => {
    if (comp.input) {
      // If the component is a data input one, return it
      return comp;
    } else {
      // If the component is not an input, check if it has child components and return them.
      let branchs: Array<FieldComp> = [];
      // Put together all child components present in the "components" and "columns" arguments, and then apply recursively the flattenFieldComponents function on it
      if (comp.components) branchs = branchs.concat(comp.components);
      return [comp, ...flattenFieldComponents(branchs, keepOnlyInputs)];
    }
  });

  // Flatten the return value
  const flattenedComponents = tree.flat();
  return keepOnlyInputs ? flattenedComponents.filter((comp) => comp.input) : flattenedComponents;
};
