import {createSlice} from "@reduxjs/toolkit";
import {fetchWithMessages} from "../utils/api/fetchWithMessages";
import {sessionsActions} from "./sessions";
import {placesActions} from "./places";
import {activitiesActions} from "./activities";
import {stewardsActions} from "./stewards";
import {registrationsActions} from "./registrations";
import {teamsActions} from "./teams";

export const currentProjectSlice = createSlice({
  name: "currentProject",
  initialState: {
    project: {},
  },
  reducers: {
    clean: (state, action) => {
      state.project = {};
    },
    changeProject: (state, action) => {
      state.project = action.payload;
    },
  },
});

const asyncActions = {
  load: (projectIdOrSlug) => async (dispatch, getState) => {
    const state = getState();

    const currentUser = state.currentUser.user;
    const currentProject = state.currentProject.project;
    const userIsConnected = !!currentUser?._id;

    // If the project loaded is already the same, and if we are already in the appropriate public/non-public mode, then, don't go further
    if (
      [currentProject._id, currentProject.slug].includes(projectIdOrSlug) &&
      currentProject.public === !userIsConnected
    ) {
      return;
    }

    // If the user is connected, load the full project, else, just get public info about the project
    const project = await fetchWithMessages(
      userIsConnected ? `projects/${projectIdOrSlug}` : `projects/${projectIdOrSlug}/public`,
      {method: "GET"}
    );

    if (!project) return; // If fetch fails, don't go further

    project.public = !userIsConnected;
    dispatch(currentProjectActions.changeProject(project));
  },
  cleanProject: () => async (dispatch, getState) => {
    await Promise.all([
      dispatch(currentProjectActions.clean()),
      dispatch(currentProjectActions.cleanProjectEntities()),
      // also clean registrations so it doesn't interfere
      dispatch(registrationsActions.setCurrent(undefined)),
      dispatch(registrationsActions.setAuthenticated(undefined)),
    ]);
  },
  cleanProjectEntities: () => async (dispatch, getState) => {
    const payload = {project: false, list: []};
    await Promise.all([
      dispatch(placesActions.initList(payload)),
      dispatch(activitiesActions.initList(payload)),
      dispatch(stewardsActions.initList(payload)),
      dispatch(sessionsActions.resetState()),
      dispatch(teamsActions.initList(payload)),
    ]);
  },
};

export const currentProjectSelectors = {
  selectProject: (state) => state.currentProject.project,
};

export const currentProjectReducer = currentProjectSlice.reducer;

export const currentProjectActions = {
  ...currentProjectSlice.actions,
  ...asyncActions,
};
