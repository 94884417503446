import {createSlice} from "@reduxjs/toolkit";
import {loadListFromBackend} from "../utils/api/loadListFromBackend";

export const placesSlice = createSlice({
  name: "places",
  initialState: {
    list: [],
    init: false,
  },
  reducers: {
    initContext: (state, action) => {
      state.init = action.payload;
    },
    initList: (state, action) => {
      state.init = action.payload.project;
      state.list = action.payload.list || [];
    },
  },
});

const asyncActions = {
  loadList: () => async (dispatch, getState) => {
    const state = getState();
    const projectId = state.currentProject.project._id;

    await loadListFromBackend(
      "places",
      projectId,
      state.places.init,
      () => dispatch(placesActions.initContext(projectId)),
      (data) => dispatch(placesActions.initList({list: data, project: projectId}))
    );
  },
};

export const placesSelectors = {
  selectList: (state) => state.places.list,
};

export const placesReducer = placesSlice.reducer;

export const placesActions = {
  ...placesSlice.actions,
  ...asyncActions,
};
