import {getSessionFromSlotOrSession} from "./getSessionFromSlotOrSession";
import {FieldCompInputType} from "../../../common/components/FieldsBuilder/fieldCompCustomProps/FieldCompInputType";
import {NOEEndpointExtended} from "../../../common/inputs/NOEObjectSelectInput";
import {getAllCustomFieldValuesForEndpoint} from "./getAllCustomFieldValuesForEndpoint";

export type CustomFieldFilter = {
  key: string,
  type: FieldCompInputType,
  endpoint: NOEEndpointExtended,
  filterValues: Array<string>,
};

// Tells if a session is selected of not by a custom field
const sessionIsSelectedByCustomField = (
  {key, endpoint, filterValues}: CustomFieldFilter,
  session,
  registrations?
): boolean => {
  const allCustomFieldValuesForObject = getAllCustomFieldValuesForEndpoint(
    key,
    endpoint,
    session,
    registrations
  );

  // We check if any of the custom fields values gathered is matching the filterValues filter
  return !!filterValues.find((value) => allCustomFieldValuesForObject?.includes(value));
};

export const filterSessionsByCustomFields = (
  slotsOrSessions,
  customFieldsFilters: Array<CustomFieldFilter>,
  registrations?
) => {
  // Only take the fields which are filtering something. If a filter doesn't have any value, it doesn't filter anything.
  const allFilteredCustomFields = customFieldsFilters.filter(
    ({filterValues}) => filterValues?.length > 0
  );

  // For each slot/session, filter them...
  return slotsOrSessions?.filter((item) => {
    // Get the session
    const session = getSessionFromSlotOrSession(item);
    // And check if it matches all the custom fields filters
    return allFilteredCustomFields.every((customField) =>
      sessionIsSelectedByCustomField(customField, session, registrations)
    );
  });
};
