import {Trans} from "react-i18next";
import React, {useEffect} from "react";
import {displayNotification} from "../../utils/displayNotification";
import {useTranslation} from "react-i18next";

let demoNotifHasBeenClosed = false;

/**
 * Demo notif when the app is in DEMO mode
 */
export const useStagingInfoNotification = () => {
  const {t} = useTranslation();

  useEffect(() => {
    // Display notif if not in production or in local development mode
    if (process.env.REACT_APP_MODE === "DEMO" && !demoNotifHasBeenClosed) {
      displayNotification("warning", "envDemoNotification", {
        message: <Trans i18nKey="demoModeNotification.message" ns="common" />,
        onClose: () => (demoNotifHasBeenClosed = true),
        description: (
          <Trans
            i18nKey="demoModeNotification.description"
            ns="common"
            values={{
              prettyProdUrl: process.env.REACT_APP_PROD_URL?.replace(/(http|https):\/\//, ""),
              contactEmail: process.env.REACT_APP_CONTACT_US_EMAIL,
            }}
            components={{
              strongOrange: <strong style={{color: "darkorange"}} />,
              linkToProdUrl: <a href={process.env.REACT_APP_PROD_URL} />,
              linkToContactEmail: (
                <a
                  href={`mailto:${process.env.REACT_APP_CONTACT_US_EMAIL}?subject=${t(
                    "common:mailtoSubjectKnowMoreAboutNOE"
                  )}`}
                  rel="noreferrer"
                />
              ),
            }}
          />
        ),
      });
    }
  }, []);
};
